<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>文件审核</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="待审核文件" icon="md-document">
            <file-list
              :columnsFormatData="columnsFormatData"
              askData="recordForVerify"
              :refreshData="update_count"
              main_width="1260px"
              :dataId="projectId"
            ></file-list>
          </TabPane>
          <TabPane label="已审核文件" icon="md-done-all">
            <file-list
              :columnsFormatData="columnsFormatData"
              askData="recordVerifyAlready"
              :refreshData="update_count"
              main_width="1260px"
              :dataId="projectId"
            ></file-list>
          </TabPane>
        </Tabs>
      </div>
      <Modal
        v-model="modalSwicth.df"
        :width="800"
        :mask-closable="false"
        @on-cancel="closeModal('df')"
      >
        <p slot="header" class="sc-header">
          <Icon type="ios-list-box" />
          <span>审核文档</span>
        </p>
        <i
          class="ivu-icon ivu-icon-ios-close"
          slot="close"
          @click="closeModal('df')"
        ></i>
        <div class="sc-content-wrapper">
          <div class="form-wrapper">
            <Form
              ref="formdf"
              :model="formItem"
              :label-width="250"
            >
              <FormItem label="" prop="name" style="width: 500px">
                <Select v-model="formItem.ifPass">
                  <Option value="ok">审核通过</Option>
                  <Option value="not">不通过</Option>
                </Select>
              </FormItem>
              <FormItem
                label="审核意见："
                prop="text"
                style="width: 500px"
                v-show="formItem.ifPass==='not'"
              >
                <Input v-model="formItem.text" placeholder />
              </FormItem>
            </Form>
          </div>
        </div>
        <div slot="footer">
          <Button
            @click="handleSubmit('formdf')"
            :loading="loadingStatus"
            size="large"
            >{{ loadingStatus ? "Uploading" : "确认提交" }}</Button
          >
          <Button
            size="large"
            @click="closeModal('df')"
            class="cancel-button"
            >取消</Button
          >
        </div>
      </Modal>
      <Modal
        v-model="modalSwicth.viewFile"
        :width="1280"
        :mask-closable="false"
        @on-cancel="closeModal('viewFile')"
      >
        <p slot="header" class="sc-header">
          <Icon type="ios-list-box" />
          <span>文件列表：</span>
        </p>
        <i
          class="ivu-icon ivu-icon-ios-close"
          slot="close"
          @click="closeModal('viewFile')"
        ></i>
        <div class="sc-content-wrapper">
          <div class="form-wrapper">
            <Table :loading="viewloading" border :columns="columnsFileView" :data="currentFileOfRecord" no-data-text="无文件"></Table>
          </div>
        </div>
        <div slot="footer">
          <Button
            size="large"
            @click="closeModal('viewFile')"
            class="cancel-button"
            >关闭</Button
          >
        </div>
      </Modal>
    </div>
  </div>
</template>
<script>
import fileList from "@/components/Common/FileList.vue";

let userToken = localStorage.getItem("current_user_token");
let authData = JSON.parse(localStorage.getItem("authData"));
let theProject = JSON.parse(localStorage.getItem("project"));

function formItemFac() {
  return {
    id: 0,
    ifPass: "",
    text:"",
    record:{}
  };
}

export default {
  name: "fileVerify",
  components: {
    fileList,
  },
  props: {},
  data() {
    return {
      update_count: 1,
      projectId:theProject.enid,
      columnsFormatData: [
        {
          title: "文件名称",
          width: 200,
          align: "center",
          key: "name",
        },
        {
          title: "涉及专业",
          key: "major",
          align: "center",
          width:100,
          render: (h, p) => {
            let majorList = [];
            for(let i of p.row.major.split(';')){
              for(let j of JSON.parse(i)){
                majorList.push(j.name);
              }
            }
            return h(
              "p",
              {},
              majorList.join(',')
            );
          }
        },
        {
          title: "涉及标准条目",
          key: "related_clause",
          align: "center",
          render: (h, p) => {
            let clauseList = JSON.parse(p.row.related_clause);
            return h(
              "div",
              clauseList.map(val=>{
                return h('p',{},
                  val.standard_no+'《'+val.standard_name+'》'+val.clause_no+'：'+val.content
                );
              })
            );
          }
        },
        {
          title: "上传人",
          align: "center",
          width:100,
          render: (h, p) => {
            return h(
              "p",
              {
              },
              p.row.upload_name?p.row.upload_name:'暂无'
            );
          }
        },
        {
          title: "上传时间",
          key: "op_time",
          align: "center",
          width:110
        },
        {
          title: "状态",
          key: "stat",
          align: "center",
          width:100
        },
        {
          title: "操作",
          align: "center",
          width: 200,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { marginRight: "10px", color: "blue" },
                  on: {
                    click: () => {
                      this.modalSwicth.viewFile = true;
                      this.getViewFile(params.row);
                    },
                  },
                },
                "查看文件"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                    disabled:params.row.stat==='已确认'||params.row.stat==='确认驳回'
                  },
                  style: { marginRight: "10px", color: (params.row.stat==='已确认'||params.row.stat==='确认驳回')?'grey':"green" },
                  on: {
                    click: () => {
                      this.formItem.record = params.row;
                      this.modalSwicth.df = true;
                    },
                  },
                },
                "审核"
              ),
            ]);
          },
        },
      ],
      modalSwicth: {
        df: false,
        viewFile:false,
      },
      formItem: formItemFac(),
      loadingStatus: false,
      currentFileOfRecord:[],
      viewloading:false,
      columnsFileView:[
        {
          title: "文件名",
          align: "center",
          key: "name",
        },
        {
          title: "大小",
          width: 100,
          align: "center",
          key: "file_size",
        },
        {
          title: "类型",
          width: 90,
          align: "center",
          key: "file_type",
        },
        {
          title: "上传时间",
          width: 180,
          align: "center",
          key: "upload_time",
        },
        {
          title: "上传人",
          width: 200,
          align: "center",
          key: "upload_name",
          render: (h, p) => {
            return h(
              "p",
              {
              },
              p.row.upload_name?p.row.upload_name:'-'
            );
          }
        },
        {
          title: "操作",
          align: "center",
          width: 90,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { marginRight: "10px", color: "blue" },
                  on: {
                    click: () => {
                      this.handleDownload(params.row);
                    },
                  },
                },
                "下载"
              ),
            ]);
          },
        },
      ],
    };
  },
  watch: {},
  computed: {},
  created() {
  },
  methods: {
    openModal(value) {
      this.modalSwicth[value] = true;
    },
    closeModal(value) {
      this.modalSwicth[value] = false;
    },
    handleReset() {
      for (let item in this.formItem) {
        this.formItem[item] = "";
      }
    },
    getViewFile(record) {
      let form = new FormData();
      form.append("userToken", userToken);
      form.append("askData", "fileOfRecord");
      form.append("recordId", record.id);
      this.viewloading = true;
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      this.$axios
        .post(this.$url + "/file_manage", form, config)
        .then((response) => {
          this.viewloading = false;
          if (response.data.stat === "ok") {
            this.currentFileOfRecord = response.data.res_record;
          } else {
            this.$Message.error(response.data.errorInfo);
          }
        })
        .catch((error) => {
          this.$Message.error(error + "未知错误！");
        });
    },
    handleDownload(item){
      window.open(this.$fileUrlFac(item.file_url,authData.sfileRight),'_blank');
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "fileVerify");
          form.append("info", JSON.stringify(this.formItem));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/file_manage", form, config)
            .then((response) => {
              this.handleReset();
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("操作成功！");
                this.update_count++;
                this.modalSwicth.df = false;
              } else {
                this.$Message.error(response.data);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 40px;
  width: 1280px;
}

.editor-title,
.preview-title {
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.re-l {
  min-height: 750px;
  color: #000;
  border: #aaa solid 1px;
  box-shadow: 1px 2px 1px #aaa;
  text-align: center;
  position: relative;
  margin: 5px;
  padding: 0 10px;
}

.form-wrapper {
  text-align: left;
  padding: 80px 120px 80px 50px;
}
</style>